<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    offset-y
    :disabled="stores.length <= 1"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        :class="[stores.length > 1 ? '' : 'disabled']"
        :ripple="stores.length > 1"
      >
        <v-progress-circular
          v-if="userLoader"
          indeterminate
          size="24"
          width="2"
        ></v-progress-circular>
        <div v-else>
          <v-icon left class="hidden-sm-and-down">mdi-store</v-icon>
          <span
            :class="[
              $vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-1',
              `text-capitalize font-weight-light`,
            ]"
          >
            {{ store.name }}
            <v-tooltip bottom color="rgba(0, 0, 0, 0.9)">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  >({{ store.target.split(":")[0] }} -
                </span>
              </template>
              <span>Code panonceau</span>
            </v-tooltip>
            <v-tooltip bottom color="rgba(0, 0, 0, 0.9)">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  >{{ store.target.split(":")[1] }})</span
                >
              </template>
              <span>Numéro émetteur</span>
            </v-tooltip>
          </span>
          <v-icon v-if="stores.length > 1" right>mdi-menu-down</v-icon>
        </div>
      </v-btn>
    </template>

    <v-list dense class="mb-n3">
      <v-list-item>
        <v-text-field
          v-if="stores.length >= 4"
          v-model="search"
          placeholder="Rechercher"
          clearable
          append-icon="mdi-magnify"
          dense
          hide-details
        ></v-text-field>
      </v-list-item>
    </v-list>

    <v-list nav dense style="max-height: 400px" class="overflow-y-auto">
      <v-list-item
        v-for="(store, i) in filteredTargets"
        :key="i"
        @click.prevent="changeStore(store)"
      >
        <v-list-item-title>
          {{ store.name }}
          <v-tooltip bottom color="rgba(0, 0, 0, 0.9)">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                >({{ store.target.split(":")[0] }} -
              </span>
            </template>
            <span>Code panonceau</span>
          </v-tooltip>
          <v-tooltip bottom color="rgba(0, 0, 0, 0.9)">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                >{{ store.target.split(":")[1] }})</span
              >
            </template>
            <span>Numéro émetteur</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    search: "",
    show: "",
  }),
  computed: {
    ...mapState({
      userLoader: (state) => state.account.userLoader,
      store: (state) => state.account.currentTarget,
      stores: (state) => state.account.targets,
    }),

    filteredTargets() {
      return this.stores.filter((item) => {
        if (!this.search) {
          return this.stores;
        }

        if (this.search.length < 2) {
          return true;
        }

        // return (
        //   item.target.match(this.search.toUpperCase()) ||
        //   item.name.match(this.search.toUpperCase())
        // );

        const search = this.search.toUpperCase();
        return (
          item.target.indexOf(search) !== -1 || item.name.indexOf(search) !== -1
        );
      });
    },
  },
  methods: {
    changeStore(store) {
      this.show = false;
      this.$store.dispatch("account/changeCurrentTarget", store);
    },
  },
};
</script>

<style scoped>
.disabled {
  cursor: default;
}
</style>
